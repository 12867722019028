<template>
  <div class="vet-card" align="center" :class="dense ? 'pa-3' : 'pa-4'">
    <div class="vet-item img" @click="$router.push(`/vet-detail/${vet.id}`)">
      <SingleImage
        :src="vet.image"
        :open-popup="false"
        max-height="50px"
        max-width="100%"
      />
    </div>
    <div class="vet-item">
      <p class="vet-name ">{{ vet.name | trim(10) }}</p>
      <p class="timing">{{ vetTime }}</p>
      <p
        class="direction primary--text"
        v-ripple
        v-if="showDirection"
        @click="openMapDirection"
      >
        <v-icon color="primary" small>mdi-arrow-right-top</v-icon>
        {{ $vuetify.lang.t("$vuetify.get_direction") }}
      </p>
    </div>
    <div class="vet-item">
      <div class="icons text-right">
        <v-icon color="primary" v-ripple class="mr-3" small @click="makeCall"
          >mdi-phone</v-icon
        >
        <v-icon small color="primary" v-ripple @click="openMail">
          mdi-mail</v-icon
        >
      </div>
    </div>
  </div>
</template>

<script>
import SingleImage from "@/components/media/single-image";
import { DEVICE } from "@/constants";

import parsePhoneNumber from "libphonenumber-js";
export default {
  name: "vet-card",
  props: {
    vet: {
      type: Object,
      required: true,
    },
    showDirection: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SingleImage,
  },
  data() {
    return {};
  },
  computed: {
    PLATFORM() {
      if (!window.cordova) return false;
      return window.device.platform;
    },
    weekDays() {
      return {
        0: "sunday",
        1: "monday",
        2: "tuesday",
        3: "wednesday",
        4: "thursday",
        5: "friday",
        6: "saturday",
      };
    },
    vetTime() {
      let timing = this.vet.timing;
      if (!timing) return "Closed";
      if (typeof timing === "string") {
        timing = JSON.parse(timing);
      }
      const day = new Date().getDay();
      const { from, to } = timing[this.weekDays[day]];
      if (!from || !to) return this.$vuetify.lang.t("$vuetify.closed");

      return `Open ${from} - ${to}`;
    },
  },
  methods: {
    openInAppBrowser(url, options = "") {
      let target = "_system";
      if (!window.cordova) target = "_blank";
      window.open(url, target, options);
    },
    makeCall() {
      const { contact } = this.vet;
      if (!contact) return;
      const num = this.isValidNumber(contact);
      if (!num)
        return window.showAlert(
          "Contact number is not valid",
          "Invalid Number"
        );
      const url = `tel:${num}`;
      this.openInAppBrowser(url);
    },
    openMail() {
      try {
        const { email } = this.vet;
        if (!email) return;
        const url = `mailto:${email}`;
        this.openInAppBrowser(url);
      } catch (error) {
        console.log(error);
      }
    },
    isValidNumber(number) {
      const phoneNumber = parsePhoneNumber(number);
      if (!phoneNumber) return false;
      if (!phoneNumber.isPossible()) return false;
      return phoneNumber.number;
    },
    openMapDirection() {
      try {
        const { latitude, longitude } = this.vet;
        let url = `https://www.google.com/maps/?q=${latitude},${longitude}`;
        if (window.cordova) {
          url = `maps://?q=${latitude},${longitude}`;
          if (this.PLATFORM === DEVICE.ANDROID) {
            url = `geo:0,0?q=${latitude},${longitude}`;
          }
        }

        this.openInAppBrowser(url, "location=yes");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
.vet-card {
  margin: 0;
  display: flex;
  align-items: center;

  .vet-item {
    &:first-child {
      max-width: 50px;
      max-height: 50px;
    }

    &:nth-child(2) {
      flex: 1;
      text-align: left;
      padding: 0 1rem;
    }
  }

  .vet-name {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 28px;
    color: #1f2937;
  }

  p.timing {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #1f2937;
    opacity: 0.5;
  }

  .direction {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-top: 5px;
  }

  .icons {
    button {
      padding: 6px;
      border-radius: 50%;
      background-color: #f5f5f5;
    }
  }
}
</style>
